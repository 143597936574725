import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MaterialTable from "material-table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { getAllManagerWithSubs } from "../../services/actions/manager/Manager";
import { updateSubscription } from "../../services/actions/subscription/Subscription";

function Manager() {
  let navigate = useNavigate();
  const [managers, setManagers] = useState([]);
  const [open, setOpen] = useState(false);
  const [idManager, setIdManager] = useState("");
  const [status, setStatus] = useState("");
  const [maxLot, setMaxLot] = useState("");
  const [subsId, setSubsId] = useState("");
  const [subsTypeId, setSubsTypeId] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleOpen = (data) => {
    setIdManager(data.id)
    setStatus(data.status);
    setSubsId(data.subsId);
    setSubsTypeId(data.subsTypeId);
    setSelectedDate(data.startDate);
    if (data.MaxProduction == "999") {
      setMaxLot("illimité");
    } else {
      setMaxLot(data.maxProduction);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setStatus("");
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      navigate("/login");
    }

    getAllManagerWithSubs().then((res) => {
      if (res.status == 200) {
        const manager = res.data.manager;
        let data = [];
        for (let index in manager) {
          data.push({
            id: manager[index].id,
            name: manager[index].firstName + " " + manager[index].lastName,
            mobile: manager[index].phoneNumber,
            status: manager[index].status,
            maxProduction:
              manager[index].Subscription.SubscriptionType.maxProduction,
            subsTypeId: manager[index].Subscription.SubscriptionType.id,
            startDate: manager[index].Subscription.startDate,
            endDate: manager[index].Subscription.endDate,
            subsId: manager[index].Subscription.id,
          });
        }
        setManagers(data);
      }
    });
  }, []);

  const handleSubsChange = (event) => {
    const type = event.target.value;
    setStatus(type);
  };

  const handleLotChange = (event) => {
    const maxLotVal = event.target.value;
    setMaxLot(maxLotVal);
    if (maxLotVal == "1") {
      setSubsTypeId(1);
    } else if (maxLotVal == "4") {
      setSubsTypeId(2);
    } else if (maxLotVal == "9") {
      setSubsTypeId(3);
    } else {
      setSubsTypeId(4);
    }
  };

  const handleSave = () => {
    const data =
      status == "active"
        ? {
            id: subsId,
            startDate: moment(selectedDate).format("YYYY-MM-DD"),
            endDate: moment(selectedDate).add(6, "months").format("YYYY-MM-DD"),
            SubscriptionTypeId: subsTypeId,
            status: status,
            ManagerId: idManager
          }
        : {
            id: subsId,
            status: status,
            ManagerId: idManager
          };

    updateSubscription(data).then((res) => {
      if (res.status == 200) {
        const updateManager = [...managers];
        const findIdx = managers.findIndex(
          (item, index) => item.subsId == subsId
        );
        if(status == "active"){
          updateManager[findIdx].startDate = data.startDate;
          updateManager[findIdx].endDate = data.endDate;
          updateManager[findIdx].status = data.status;
          updateManager[findIdx].maxProduction = maxLot;
        }else{
          updateManager[findIdx].status = data.status;
        }
        setManagers(updateManager);
        setOpen(false);
        setStatus("");
      }
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MaterialTable
        title="List of subscriptions"
        columns={[
          { title: "Name", field: "name" },
          { title: "Mobile", field: "mobile" },
          { title: "Status", field: "status" },
          {
            title: "Max Production",
            field: "maxProduction",
          },
          {
            title: "Start date",
            field: "startDate",
          },
          {
            title: "End date",
            field: "endDate",
          },
        ]}
        data={managers}
        style={{ fontSize: 15, padding: "1%" }}
        actions={[
          {
            icon: "edit",
            tooltip: "Save User",
            onClick: (event, rowData) => handleOpen(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />

      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Update Manager</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
            }}
          >
            <FormControl style={{ marginTop: 5 }}>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                sx={{ p: 1 }}
                value={status}
                onChange={handleSubsChange}
                label="status"
                inputProps={{
                  name: "status",
                  id: "status",
                }}
              >
                <MenuItem key={0} value="active">
                  active
                </MenuItem>
                <MenuItem key={1} value="inactive">
                  inactive
                </MenuItem>
              </Select>
            </FormControl>
            {status == "active" ? (
              <>
                <FormControl style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="maxLot">MaxLot</InputLabel>
                  <Select
                    sx={{ p: 1 }}
                    value={maxLot}
                    onChange={handleLotChange}
                    label="maxLot"
                    inputProps={{
                      name: "maxLot",
                      id: "maxLot",
                    }}
                  >
                    <MenuItem key={0} value="1">
                      1
                    </MenuItem>
                    <MenuItem key={1} value="4">
                      4
                    </MenuItem>
                    <MenuItem key={2} value="9">
                      9
                    </MenuItem>
                    <MenuItem key={3} value="illimité">
                      illimité
                    </MenuItem>
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="start-date"
                    label="start date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Manager;
